import React from 'react';

import ScheduleSharpIcon from '@material-ui/icons/ScheduleSharp';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
export default class TaskView extends React.Component {

    render() {
        return (
            <React.Fragment>

                <div className="timeline timeline-taskview">

                    <header className="timeline-month">
                        <div className="tm1">
                            <div></div>
                        </div>
                        <nav>
                            <div> 12. August 2020</div>
                            <div>3 Tasks</div>
                            <div><ScheduleSharpIcon/> <span>{this.props.dayTime} </span></div>
                        </nav>
                    </header>

                    <div className="timeline-section">

                        <div className="timeline-date">
                            <div></div>
                            <div></div>
                            <div className="timeline-date--time">8:20</div>
                            <div></div>
                        </div>

                        <div className="timeline-row">
                            <div className="timeline-box">
                                <div className="box-title">
                                    <div className="icon icon-blue"><TripOriginIcon /></div> Job Created
                                </div>
                                <div className="box-content">

                                    <div className="box-item"><strong>Service Type</strong>: A/C Leak</div>
                                    <div className="box-item"><strong>Service Territory</strong>: Texas</div>
                                    <div className="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                                </div>
                                <div className="box-footer">- Tyler</div>
                            </div>
                            <div className="timeline-box">
                                <div className="box-title">
                                    <i className="fa fa-asterisk text-success" aria-hidden="true"></i> Job Created
                                </div>
                                <div className="box-content">
                                    <div className="box-item"><strong>Service Type</strong>: A/C Leak</div>
                                    <div className="box-item"><strong>Service Territory</strong>: Texas</div>
                                    <div className="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                                </div>
                                <div className="box-footer">- Tyler</div>
                            </div>
                        </div>
                    </div>
                    <div className="timeline-section">
                        <div className="timeline-row">
                            {/* Lambda sensor test */}
                            <div className="timeline-box w-100">
                                <div className="box-title">
                                    <div className="icon icon-green"><TripOriginIcon /></div> Lambda sensor test
                                </div>
                                <div className="box-content">

                                    <div className="box-item"><strong>Service Type</strong>: A/C Leak</div>
                                    <div className="box-item"><strong>Service Territory</strong>: Texas</div>
                                    <div className="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                                </div>
                                <div className="box-footer">- Tyler</div>
                            </div>
                            {/* Replacement cylinder */}
                            <div className="timeline-box w-100">
                                <div className="box-title">
                                    <div className="icon icon-orange"><TripOriginIcon /></div> Replacement cylinder
                                </div>
                                <div className="box-content">

                                    <div className="box-item"><strong>Service Type</strong>: A/C Leak</div>
                                    <div className="box-item"><strong>Service Territory</strong>: Texas</div>
                                    <div className="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                                </div>
                                <div className="box-footer">- Tyler</div>
                            </div>
                            <div className="timeline-box w-100">
                                <div className="box-title">
                                    <div className="icon icon-orange"><TripOriginIcon /></div> Lamdasonde
                                </div>
                                <div className="box-content">

                                    <div className="box-item"><strong>Service Type</strong>: A/C Leak</div>
                                    <div className="box-item"><strong>Service Territory</strong>: Texas</div>
                                    <div className="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                                </div>
                                <div className="box-footer">- Tyler</div>
                            </div>
                            <div className="timeline-box w-100">
                                <div className="box-title">
                                    <div className="icon icon-orange"><TripOriginIcon /></div> Lamdasonde
                                </div>
                                <div className="box-content">

                                    <div className="box-item"><strong>Service Type</strong>: A/C Leak</div>
                                    <div className="box-item"><strong>Service Territory</strong>: Texas</div>
                                    <div className="box-item"><strong>Start Date</strong>: 08/22/2018</div>
                                </div>
                                <div className="box-footer">- Tyler</div>
                            </div>
                        </div>
                    </div>

                </div>

            </React.Fragment>
        );
    }
}