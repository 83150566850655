import React from 'react';
import {TasksConfig as DayViewTasks} from './TasksConfig';
import TimelineSection from "./TimelineSection"

import ScheduleSharpIcon from '@material-ui/icons/ScheduleSharp';



export default class CalendarDayView extends React.Component {

    render() {
        const tasks = DayViewTasks;
        const dayTime = this.props.dayTime;
        const taskStartTime = this.props.taskStartTime;

        return (
            <React.Fragment>


                <div className="timeline">

                    <header className="timeline-month">
                        <div className="tm1">
                            <div></div>
                        </div>
                        <nav>
                            <div> 12. August 2020</div>
                            <div>6 Tasks</div>
                            <div><ScheduleSharpIcon/> <span>{dayTime} </span></div>
                        </nav>
                    </header>

                    <TimelineSection tasks={tasks} dayTime={dayTime} taskStartTime={taskStartTime} />

                </div>

            </React.Fragment>
        );
    }
}