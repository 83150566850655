import React from 'react';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import ViewDaySharpIcon from '@material-ui/icons/ViewDayOutlined';
import BuildSharpIcon from '@material-ui/icons/BuildSharp';
import {Link} from "react-router-dom";

export default class Sidebar extends React.Component {
    render() {
        return (
            <React.Fragment>
                <ul className="app-sidebar--elements">
                    <li>
                        <Link to="/day-view">
                            <button className="button button-sidebar--alendarToday"><CalendarTodayOutlinedIcon/>
                            </button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/task-view">
                            <button className="button button-sidebar--viewDay"><ViewDaySharpIcon/></button>
                        </Link>
                    </li>
                    <li>
                        <Link to="/">
                            <button className="button button-sidebar--Build"><BuildSharpIcon/></button>
                        </Link>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}