import React from 'react';
import {
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import "../Theme/Theme.scss";
import Sidebar from "./Sidebar";
import CalendarDayView from "./Pages/Calendar/DayView";
import TaskView from "./Pages/Task/TaskView";

const dayTime = "09:15:22";
const taskStartTime = "10:20:00";

const routes = [
    {
        path: "/day-view",
        exact: true,
        appContent: () => <CalendarDayView dayTime={dayTime} taskStartTime={taskStartTime}/>
    },
    {
        path: "/task-view",
        exact: true,
        appContent: () => <TaskView dayTime={dayTime} taskStartTime={taskStartTime}/>
    }
];



export default class Application extends React.Component {
    render() {
        return (
            <Router>
                <div className="app-container">
                    <div className="app-sidebar">
                        <Sidebar/>
                    </div>
                    <div className="app-content">
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        children={<route.appContent/>}
                                    />
                                ))}
                            </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}
