import React from "react";
import TripOriginIcon from "@material-ui/icons/TripOrigin";

export default class TimelineSection extends React.Component {


    getTaskStatus = (dayTime, taskTime) => {

        if (taskTime < dayTime) {
            return "finished";
        }
        if (taskTime > dayTime) {
            return "next";
        }
    }

    setTaskStartTime = (timeString, counter) => {
        const date = new Date('01 Jan 1970 ' + timeString + ' GMT');

        /*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
        Date.prototype.addHours = function (h) {
            this.setHours(this.getHours() + h);
            return this;
        }

        date.addHours(counter);
        return date.toLocaleTimeString();
    }

    render() {

        return <React.Fragment>
            {
                this.props.tasks.map((task, index) => {

                    const dayTime = this.props.dayTime;
                    const taskStartTime = task.startTime;
                    let taskStatus = this.getTaskStatus(dayTime, taskStartTime);
                    if (taskStartTime === this.props.taskStartTime){
                        taskStatus = "active";
                    }

                    return (
                        <div key={index} className="timeline-section">
                            <div className="timeline-date">
                                <div></div>
                                <div></div>
                                <div className={'timeline-date--time ' + taskStatus}>{taskStartTime}</div>
                            </div>
                            <div className="timeline-row">
                                {task.task.map((task, i) => <div key={i} className="timeline-box">
                                        <div className="box-title">
                                            <div className={'icon task-icon--' + taskStatus}><TripOriginIcon/></div>
                                            {task.description} <span className={taskStartTime}>{task.startTime}</span>
                                        </div>
                                        <div className="box-content">

                                            <div className="box-item"><strong>Service Type</strong>: {task.type}</div>
                                            <div className="box-item"><strong>Service Territory</strong>: Halle A1-12</div>
                                            <div className="box-item"><strong>Service Created</strong>: 08/22/2020</div>
                                        </div>
                                        <div className="box-footer">- Tyler</div>
                                    </div>
                                )}
                            </div>

                        </div>

                    )
                })
            }
        </React.Fragment>
    }

}
