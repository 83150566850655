export const TasksConfig = [
    {

        startTime: "08:20:00",
        task: [
            {
                startTime: "08:20:00",
                type: "ECOS",
                description: "Kundenberatung"
            },
            {
                startTime: "09:20:00",
                type: "MOBIS",
                description: "Replacement cylinder"
            }
        ]
    },
    {
        startTime: "10:20:00",
        task: [
            {

                startTime: "11:00:00",
                type: "ECOS",
                description: "Kundenberatung"
            },
            {
                startTime: "11:45:00",
                type: "ECOS",
                description: "Kundenberatung"
            },
            {
                startTime: "12:20:00",
                type: "ECOS",
                description: "Kundenberatung"
            }
        ]
    },
    {
        startTime: "14:20:00",
        task: [
            {
                startTime: "15:00:00",
                type: "ECOS",
                description: "Kundenberatung"
            },
            {
                startTime: "15:30:00",
                type: "ECOS",
                description: "Kundenberatung"
            }
        ]
    },
    {
        startTime: "16:00:00",
        task: [
            {
                startTime: "16:15:00",
                type: "ECOS",
                description: "Kundenberatung"
            },
            {
                startTime: "17:15:00",
                type: "ECOS",
                description: "Kundenberatung"
            },
            {
                startTime: "17:45:00",
                type: "ECOS",
                description: "Kundenberatung"
            }
        ]
    },
    {
        startTime: "18:00:00",
        task: [
            {
                startTime: "18:15:00",
                type: "ECOS",
                description: "Kundenberatung"
            },
            {
                startTime: "18:45:00",
                type: "ECOS",
                description: "Kundenberatung"
            }
        ]
    }
];
