import React from 'react';
import ReactDOM from 'react-dom';
import Application from './Application/Application';
import * as serviceWorker from './serviceWorker';
// Load Roboto typeface
require('typeface-roboto');



ReactDOM.render(
    <React.Fragment>
        <Application />
    </React.Fragment>,
    document.getElementById('root')
);

serviceWorker.unregister();
